import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/games.scss"
import "../../styles/game-page.scss"

// Images
import bg from "../../images/data/metaphora/meta/bg.jpg"

import keyart from "../../images/data/metaphora/hero/keyart.jpg"
import keyart_thumbnail from "../../images/data/metaphora/hero/keyart-thumbnail.jpg"
import gameplay_thumbnail from "../../images/data/metaphora/hero/gameplay-thumbnail.jpg"

import game_about from "../../images/data/metaphora/meta/icon.jpg"
import game_logo from "../../images/data/metaphora/meta/logo.png"
import game_developer from "../../images/data/metaphora/meta/developer.png"

import slide_1 from "../../images/data/metaphora/slider/a.jpg"
import slide_2 from "../../images/data/metaphora/slider/b.jpg"
import slide_3 from "../../images/data/metaphora/slider/c.jpg"
import slide_4 from "../../images/data/metaphora/slider/d.jpg"
import slide_5 from "../../images/data/metaphora/slider/e.jpg"
import slide_6 from "../../images/data/metaphora/slider/f.jpg"
import slide_7 from "../../images/data/metaphora/slider/g.jpg"
import slide_8 from "../../images/data/metaphora/slider/h.jpg"
import slide_9 from "../../images/data/metaphora/slider/i.jpg"
import slide_10 from "../../images/data/metaphora/slider/j.jpg"

import award_1 from "../../images/data/metaphora/awards/1.png"
import award_2 from "../../images/data/metaphora/awards/2.png"
import award_3 from "../../images/data/metaphora/awards/3.png"

import press1 from "../../images/data/metaphora/press/1.png"
import press2 from "../../images/data/metaphora/press/2.png"

import thumbnail_1 from "../../images/data/wonhon/thumbnail.jpg"
import thumbnail_2 from "../../images/data/tiltpack/thumbnail.jpg"
import thumbnail_3 from "../../images/data/retro-machina/thumbnail.jpg"

import game_logo_1 from "../../images/data/wonhon/meta/logo.png"
import game_logo_2 from "../../images/data/tiltpack/meta/logo.png"
import game_logo_3 from "../../images/data/retro-machina/meta/logo.png"

// Videos
import video from "../../videos/metaphora.mp4"

const GamePage = () => {

  return (
    <Layout>

      <Helmet>
        <body className="game-page"/>
      </Helmet>

      <SEO title="Metaphora The Moonycat Queest"/>
      <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
      <Link to="/games/" className="title">Games</Link>

      <div className="games">

        <div className="center">

          <section className="games__card -alone">

            <div className="games__card__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
            
            <h3 className="games__card__title">
              <span className="games__card__title__link">
                METAPHORA <br/>
                THE MOONYCAT QUEST
                <span className="games__card__title__link__released">
                  <span>Q2 2023</span>
                </span>
              </span>
            </h3>

            <div className="games__card__content">
              <div className="games__card__video">
                <div className="games__card__video__visible -alone">
                  <img src={keyart} className="games__card__video__visible__size-setter"  alt=""/>
                  <div className="games__card__video__visible__item" data-id="keyart">
                    <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                      <source src={video} type="video/mp4"/>
                    </video>
                    <img src={keyart} className="games__card__video__visible__item__media"  alt=""/>
                  </div>
                  {/* <div className="games__card__video__visible__item" data-id="gameplay">
                    <YouTube
                      videoId="k_ibr8Fhz30"
                      opts={{
                        playerVars: {
                          controls: 0,
                          playlist: 'k_ibr8Fhz30',
                          loop: 1,
                          showinfo: 0,
                          autoplay: 1,
                          start: 0,
                          mute: 1,
                          enablejsapi: 1
                        }
                      }}
                      // onReady={_onReady}
                    ></YouTube>
                  </div> */}
                </div>
                <button className="games__card__video__button -key-art -active -alone" data-id="keyart" style={{backgroundImage: 'url(' + keyart_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Key Art</span>
                </button>
                {/* <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + gameplay_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Gameplay</span>
                </button> */}
              </div>
            </div>

            <footer className="games__card__footer">
              <div className="games__card__footer__about">
                <div className="games__card__footer__about__image">
                  <img src={game_about} alt="Metaphora"/>
                </div>
                <div className="games__card__footer__about__text">
                  An animated action-adventure with an engaging story about regenerating the world by switching between characters, exploring, solving puzzles, and fighting contamination. <br/>
                  In Metaphora, you explore the planet and make the world blossom again in a collaborative way by switching among three types of cute characters, each with unique abilities.
                  <div className="games__card__footer__about__text__developer">
                    <div className="games__card__footer__about__text__developer__label">Game developer</div>
                    <a href="https://www.moonycat.com" className="games__card__footer__about__text__developer__logo" target="_blank" rel="noopener noreferrer">
                      <img src={game_developer} alt=""/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="games__card__footer__stores">
                {/* <div className="games__card__footer__stores__line">
                  <div className="games__card__footer__stores__line__item -steam">steam</div>
                  <div className="games__card__footer__stores__line__item -switch">switch</div>
                </div>
                <div className="games__card__footer__stores__line">
                  <div className="games__card__footer__stores__line__item -ps">ps</div>
                  <div className="games__card__footer__stores__line__item -xbox">xbox</div>
                </div> */}
              </div>
              <img src={game_logo} className="games__card__footer__logo" alt="Metaphora"/>
            </footer>
            
          </section>

        </div>

      </div>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Key <span className="game-section__title__text__accent">Features</span>
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__features">
              <div className="game-section__features__item">
                <b>Save and revive the planet</b>
              </div>
              <div className="game-section__features__item">
                <b>Join forces to achieve your goal</b>
              </div>
              <div className="game-section__features__item">
                <b>A cute game with non-violent combat</b>
              </div>
            </div>

            <div className="game-section__slider -collapsed">
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide">
                      <img src={slide_1} alt="" data-index="0"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_2} alt="" data-index="1"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_3} alt="" data-index="2"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_4} alt="" data-index="3"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_5} alt="" data-index="4"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_6} alt="" data-index="5"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_7} alt="" data-index="6"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_8} alt="" data-index="7"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_9} alt="" data-index="8"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_10} alt="" data-index="9"/>
                    </li>
                  </ul>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                  <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Game <span className="game-section__title__text__accent">Trailer</span>
            </span>
          </h4>

          <div className="game-section__content -trailer">
            <YouTube
              videoId="fOJrx0bGuOQ"
              opts={{
                playerVars: {
                  controls: 1,
                  playlist: 'fOJrx0bGuOQ',
                  loop: 1,
                  showinfo: 0,
                  autoplay: 0,
                  start: 0,
                  mute: 1,
                  enablejsapi: 1
                }
              }}
              // onReady={_onReady}
            ></YouTube>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Awards <span className="game-section__title__text__accent">We Are Proud Of</span>
            </span>
          </h4>

          <div className="game-section__content">
            <div className="game-section__awards">
              <div className="game-section__awards__item">
                <img src={award_1} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_2} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_3} alt=""/>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title -small">
            <i></i>
            <span className="game-section__title__text">
              You may like our other games
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__recents">
              <Link to="/games/wonhon/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_1 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>WONHON A VENGEFUL SPIRIT</span>
                </span>
                <span className="game-section__recents__item__text">
                  wonhon is a story-driven tactical turnbased
                  RPG set in an alternative WW2
                </span>
                <img src={game_logo_1} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/tiltpack/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_2 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>Tilt Pack</span>
                </span>
                <span className="game-section__recents__item__text">
                  Push and stomp your way to victory
                  in this exciting brawler for friends
                  and family!
                </span>
                <img src={game_logo_2} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/retro-machina/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_3 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>Retro Machina</span>
                </span>
                <span className="game-section__recents__item__text">
                  Retro Machina is a story-driven tactical turnbased
                  RPG set in an alternative WW2
                </span>
                <img src={game_logo_3} className="game-section__recents__item__image" alt=""/>
              </Link>
            </div>

            <div className="games__promo__button">
              <button className="games__promo__button__link" data-role="call-to-action">
                <span className="games__promo__button__link__text">Give a boost to your game</span>
              </button>
              <div className="games__promo__button__shadow"></div>
            </div>

          </div>

        </div>
      </section>

      <div className="game-section__slider -expanded">
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="glide__slide">
                <img src={slide_1} alt="" data-index="0"/>
              </li>
              <li className="glide__slide">
                <img src={slide_2} alt="" data-index="1"/>
              </li>
              <li className="glide__slide">
                <img src={slide_3} alt="" data-index="2"/>
              </li>
              <li className="glide__slide">
                <img src={slide_4} alt="" data-index="3"/>
              </li>
              <li className="glide__slide">
                <img src={slide_5} alt="" data-index="4"/>
              </li>
              <li className="glide__slide">
                <img src={slide_6} alt="" data-index="5"/>
              </li>
              <li className="glide__slide">
                <img src={slide_7} alt="" data-index="6"/>
              </li>
              <li className="glide__slide">
                <img src={slide_8} alt="" data-index="7"/>
              </li>
              <li className="glide__slide">
                <img src={slide_9} alt="" data-index="8"/>
              </li>
              <li className="glide__slide">
                <img src={slide_10} alt="" data-index="9"/>
              </li>
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
          </div>
        </div>
      </div>
      
    </Layout>
  );

}

export default GamePage
